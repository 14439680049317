html {
  min-height: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

/* TODO: remove from here */
.custom-date-picker .input-clear-zone {
  position: absolute;
  right: 44px;
  top: 6px;
  cursor: pointer;
}

/* TODO: remove from here */
.nav-link {
  color: black;
}

.nav-link.active {
  font-weight: bold;
}

.custom-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-table > * {
  width: 99%;
}

.react-datepicker-wrapper {
  width: 100%;
}

.custom-dropdown-menu {
  max-height: 450px;
  overflow-y: scroll;
}

th {
  position: sticky;
  top: 0;
  background-color: white;
}

.modal-body {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.form-check {
  padding-left: 2.25rem
}

.ant-spin-dot-item {
  margin: 8px
}

.ant-table-tbody tr:nth-child(2n) td {
  background-color: #fbfbfb;
}

.ant-table-cell {
  text-wrap: nowrap;
}
